import React from 'react'
import Layout from '../../staticcomponents/layout'
import './videolanding.scss'
import VideoLandingHeader from './videolandingheader'

export default function VideoLanding() {
    return (
        <>
            <Layout>
                <div className='video-landing-main'>
                    <div className='video-donut-bg'>
                        <div className="capsules-bg-right ">
                            <VideoLandingHeader />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}