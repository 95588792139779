import React, { useState } from 'react';
import { Grid, Paper, Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import video_1 from '../../../assets/Video1.png';
import video_2 from '../../../assets/Video2.png';
import video_3 from '../../../assets/Video3.png';
import LoadMoreButton from '../../../staticcomponents/LoadMoreButton';
import './videolandingheader.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />;
});

export default function VideoLandingHeader() {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Container maxWidth="xl" className='videolanding-cls'>
            <Grid container>
                <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
                <Grid container xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div className='tiles-container'>
                        <h1>videos</h1>
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="container-padding">

                            {/* video 1 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_1}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 2 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_2}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 3 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_3}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 4 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_1}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 5 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_2}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 6 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_3}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 7 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_1}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 8 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_2}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 9 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_3}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 10 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_1}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 11 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_2}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* video 12 tile */}
                            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={0} variant='outlined' className='video-container'>
                                    <Button className='button-container' onClick={handleClickOpen}>
                                        <img src={video_3}
                                            alt='video'
                                            className='video-img' />
                                        <div className='video-img-overlay'></div>
                                        <PlayCircleIcon fontSize='large' className='play-button-icon' />
                                    </Button>
                                    <Grid>
                                        <Paper elevation={0} className='tiles-details'>
                                            <p className='video-title'>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                                            {/* <p className='video-publishing-date'>JUL 2022</p> */}
                                        </Paper>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
            </Grid>
            {/* Load More Butotn Starts */}
            <LoadMoreButton />
            {/* Load More Butotn Ends */}
            {/**Dialog Start**/}
            <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth='md'>
                <Box className='popup-overlay'>
                    <IconButton className='popup-close-icon' onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className='cust-dialog'>
                        <Grid container className='dialog-content'>
                            <div className='iframe-class'>
                                <iframe
                                    width='560'
                                    height='349'
                                    className='responsive-iframe'
                                    src='https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false' />
                            </div>
                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>

        </Container>
    )
}